import React from "react"
import { ThemeProvider } from "styled-components"

import theme from "../../../../gatsby-theme-material-ui-top-layout/theme"
import * as S from "./style"
import { useStyles } from "./styles.material"
import { Footer } from "../../../molecules/Footer"
import {
  FormattedMessage,
  Link,
  MessageDescriptor,
  useIntl,
} from "gatsby-plugin-react-intl"
import { homeMessages } from "../../../../messages/home"
import { UserTray } from "../../../molecules/UserTray"
import { CssBaseline, AppBar, Toolbar, Tabs, Tab } from "@mui/material"

export type TabType =
  // | "home"
  | "app"
  // | "resources"
  | "learning-sources"
  | "creators"
  | "organisations"
  | "blog"
// | "learn-api"
// | "courses"
// | "detailed-knowledge"
// | "courses-groups"
// | "private-lessons"
// | "organizations"
// | "schools"

export interface Tab {
  type: TabType
  path: string
  message: MessageDescriptor
  disabled?: boolean
}

const tabs: Tab[] = [
  { type: "app", path: "/", message: homeMessages.app },
  // { type: "home", path: "/", message: homeMessages.home },
  // {
  //   type: "resources",
  //   path: "/resources",
  //   message: homeMessages.learningResources,
  // },
  {
    type: "learning-sources",
    path: "/learning-sources",
    message: homeMessages.learningSources,
  },
  // {
  //   type: "creators",
  //   path: "/creators",
  //   message: homeMessages.creators,
  // },
  // {
  //   type: "organisations",
  //   path: "/organisations",
  //   message: homeMessages.organisations,
  // },
  // {
  //   type: "blog",
  //   path: "/blog",
  //   message: homeMessages.blog,
  // },
  // {
  //   type: "learn-api",
  //   path: "/learn-api",
  //   message: homeMessages.learnApi,
  // },
  // { type: "courses", message: homeMessages.courses },
  // { type: "detailed-knowledge", message: homeMessages.detailedKnowledge },
  // { type: "private-lessons", message: homeMessages.privateLessons },
  // { type: "organizations", message: homeMessages.organizations },
  // { type: "schools", message: homeMessages.schools },
]

function a11yProps(tab: TabType) {
  return {
    id: `tab-${tab}`,
    "aria-controls": `tabpanel-${tab}`,
  }
}

export type HomeLayoutVariant = "default" | "dark"

interface HomeLayoutProps {
  activeTab: TabType
  // remoteLocation: boolean
  // onRemoteLocationChange: (remoteLocation: boolean) => void
  // cities: any[] | null
  // languages: any[] | null
  // selectedCity?: { id?: string; name: string }
  // onSelectedCityChange: (city?: { id?: string; name: string }) => void
  // selectedLanguage?: { id?: string; code: string }
  // onSelectedLanguageChange: (language?: { id?: string; code: string }) => void
  onTabChange: (tab: Tab) => void
  variant?: HomeLayoutVariant
}

const HomeLayout: React.FC<HomeLayoutProps> = ({
  activeTab,
  children,
  // remoteLocation,
  // onRemoteLocationChange,
  // cities,
  // languages,
  // selectedCity,
  // onSelectedCityChange,
  // selectedLanguage,
  // onSelectedLanguageChange,
  onTabChange,
  variant = "default",
}) => {
  const intl = useIntl()
  const classes = useStyles()

  const handleTabChange = (event: React.ChangeEvent<{}>, newTab: number) => {
    onTabChange(tabs[newTab])
  }

  return (
    <ThemeProvider theme={theme}>
      <S.Wrapper>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <S.Title>
              <Link to="/">LearnTray</Link>
            </S.Title>
            {/* <S.User>
              <UserTray />
            </S.User> */}
          </Toolbar>
          <Tabs
            value={tabs.findIndex(tab => tab.type === activeTab)}
            onChange={handleTabChange}
            aria-label="main website menu"
            textColor="inherit"
            className={classes.tabs}
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs.map(tab => (
              <Tab
                key={tab.type}
                className={classes.tab}
                label={intl.formatMessage(tab.message)}
                disabled={tab.disabled}
                {...a11yProps(tab.type)}
              />
            ))}
          </Tabs>
        </AppBar>
        <S.Content variant={variant}>
          {children}
          <S.Footer>
            <Footer light={variant === "dark"} />
          </S.Footer>
        </S.Content>
      </S.Wrapper>
    </ThemeProvider>
  )
}

export { HomeLayout }
