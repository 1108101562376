import React from "react"
import styled, { css } from "styled-components"
import { Button } from "@mui/material"
import { Link } from "gatsby-plugin-react-intl"
import { HomeLayoutVariant } from "./HomeLayout"

export const Wrapper = styled.div`
  width: 100%;
`

export const Content = styled.div<{ variant: HomeLayoutVariant }>`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

  ${props =>
    props.variant === "dark" &&
    css`
      background: #003891;
      color: #fff;
    `}
`

export const Title = styled.h1`
  grid-area: title;
  margin: 6px 0 0 0;
  font-size: 1.4em;
  text-align: center;
  width: 100%;
  // Disable left alignment as login button/icon is disabled
  /* @media only screen and (max-width: 960px) {
    text-align: left;
  } */
  @media only screen and (min-width: 600px) and (max-width: 960px) {
    margin-bottom: 16px;
  }
  a {
    /* color: #fff; */
    color: ${props => props.theme.palette.text.primary};
    text-decoration: none;
  }
`

export const RemoteCheckbox = styled.div`
  grid-area: remoteCheckbox;
  white-space: nowrap;
  margin: 3px 0 0 0;
`

export const LanguageInput = styled.div`
  grid-area: languageInput;
  margin: 3px 0 0 0;
`

export const CityInput = styled.div`
  grid-area: cityInput;
  margin: 3px 0 0 0;
`

export const User = styled.div`
  grid-area: user;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 24px;
  @media only screen and (max-width: 960px) {
    position: unset;
    right: unset;
  }
  @media only screen and (min-width: 600px) and (max-width: 960px) {
    margin-bottom: 16px;
  }
`
export const DocsLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  @media only screen and (max-width: 600px) {
    margin-left: auto;
  }
`
export const DocsButton = styled(props => (
  <Button variant="outlined" {...props} />
))`
  margin: 6px 14px 0 0;
  color: rgba(0, 0, 0, 0.8);
  white-space: nowrap;
  border-color: rgba(0, 0, 0, 0.8);
  background: #fffdd5;

  &:hover {
    background: #ffeab1;
  }
`

export const Footer = styled.div`
  margin: 40px 0 10px 0;
  width: 100%;
`
