import { useLocation } from "@reach/router"
import { useIntl } from "gatsby-plugin-react-intl"

export const useIntlPathName = () => {
  const intl = useIntl()
  const location = useLocation()

  const locale = intl.locale
  const pathname = location.pathname

  return locale
    ? pathname.replace(new RegExp("^(/" + locale + ")", "g"), "")
    : pathname
}
