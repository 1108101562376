import { Theme } from "@mui/material"
import { makeStyles, createStyles } from "@mui/styles"

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      alignItems: "center",
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: "#fff",
      color: theme.palette.text.primary,
    },
    toolbar: {
      width: "100%",
      alignItems: "flex-start",
      gridColumnGap: "20px",
      paddingTop: theme.spacing(1),
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        display: "grid",
        gridTemplateAreas: `"title title user" "languageInput cityInput remoteCheckbox"`,
        gridTemplateColumns: "1fr 1fr auto",
        margin: "0 0 6px 0",
      },
      [theme.breakpoints.down("xs")]: {
        display: "grid",
        gridTemplateAreas: `"title user user" "remoteCheckbox remoteCheckbox remoteCheckbox" "languageInput cityInput cityInput"`,
        gridTemplateColumns: "1fr auto 1fr",
        margin: "0 0 6px 0",
      },
    },
    tabs: {
      // "& .MuiTabs-indicator": {
      //   backgroundColor: "#fff",
      // },
    },
    tab: {
      minWidth: "unset",
    },
    checkboxFormControlLabel: {
      margin: "0 16px 0 -11px",
      [theme.breakpoints.down("xs")]: {
        margin: "6px 16px 0 -11px",
      },
    },
    checkbox: {
      color: "#fff",
      "&.Mui-checked": {
        color: "#fff",
      },
    },
    languageInputAutoComplete: {
      marginBottom: "6px",
      width: "200px",
      [theme.breakpoints.down("sm")]: {
        width: "unset",
      },
    },
    cityInputAutoComplete: {
      marginBottom: "6px",
      width: "300px",
      [theme.breakpoints.down("md")]: {
        width: "200px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "unset",
      },
    },
    selectInputInput: {
      "& .MuiInputBase-root": {
        padding: "0 0 0 12px",
      },
      "& .MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before":
        {
          borderBottomColor: "#fff",
        },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#fff",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#fff6",
        },
        "&:hover fieldset": {
          borderColor: "#fff6",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#fff6",
        },
      },
      "& .MuiAutocomplete-endAdornment": {
        right: "12px",
      },
    },
  })
)
