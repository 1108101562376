import { defineMessages } from "gatsby-plugin-react-intl"

export const homeMessages = defineMessages({
  home: {
    defaultMessage: "Home",
    description: "menu item",
  },
  app: {
    defaultMessage: "App",
    description: "menu item",
  },
  learningSources: {
    defaultMessage: "Learning sources",
    description: "menu item",
  },
  creators: {
    defaultMessage: "Creators",
    description: "menu item",
  },
  organisations: {
    defaultMessage: "Organisations",
    description: "menu item",
  },
  blog: {
    defaultMessage: "Blog",
    description: "menu item",
  },
  learnApi: {
    defaultMessage: "Learn API",
    description: "menu item",
  },
  organizations: {
    defaultMessage: "Organizations",
    description: "menu item",
  },
  courses: {
    defaultMessage: "Courses",
    description: "menu item",
  },
  detailedKnowledge: {
    defaultMessage: "Detailed knowledge",
    description: "menu item",
  },
  coursesGroups: {
    defaultMessage: "Courses groups",
    description: "menu item",
  },
  privateLessons: {
    defaultMessage: "Private lessons",
    description: "menu item",
  },
  schools: {
    defaultMessage: "Schools",
    description: "menu item",
  },
})
